import React from "react"
import classNames from "classnames"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import { ButtonGroupMap } from "../../components/Button"
import { Image } from "../../components/Core"

function InTheCommunity(props) {
  if (!props.data.hasThisSection) {
    return <></>
  }

  const mainClasses = classNames(
    "in-the-community",
    "show-phone-only-inverse",
    "is-vcentered",
    {
      "color-back": props.colorBack
    }
  )

  const mainMobileClasses = classNames(
    "in-the-community",
    "show-phone-only-block",
    {
      "color-back": props.colorBack
    }
  )

  return (
    <>
      <HorizontalTwoColumns
        className={mainClasses}
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={1}
        leftColumn={
          <div
            className="column"
            data-aos="fade-right"
            data-aos-duration="1200">
            <Image publicId={props.data.imageId} />
          </div>
        }
        rightColumn={
          <div
            className="column community-text-block"
            data-aos="fade-left"
            data-aos-duration="1200">
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
      />
      <HorizontalTwoColumns
        className={mainMobileClasses}
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        leftColumn={
          <div className="column">
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
            <Image publicId={props.data.imageId} />
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
        rightColumn={<div></div>}
      />
    </>
  )
}

export default InTheCommunity
