import React from "react"
import SizzleVideo from "../../components/reactPlayerTest"
import "../../components/animations/scroll.scss"

function Sizzle(props) {
  return (
    <div className="home-sizzle-wrapper">
      <SizzleVideo playing={props.playing} id="test-player-1" />
      {props.children}
    </div>
  )
}

export default Sizzle
