import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"

import DesktopSizzle from "../../src/videos/sizzle--desktop.mp4"
import MobileSizzle from "../../src/videos/sizzle--mobile.mp4"

const SizzleSource = ({ onChange }) => {
  const [src, setSrc] = useState(DesktopSizzle)
  const isMobile = useMediaQuery({ maxWidth: 767 })

  useEffect(() => {
    if (isMobile) {
      setSrc(MobileSizzle)
      onChange(MobileSizzle)
    } else {
      setSrc(DesktopSizzle)
      onChange(DesktopSizzle)
    }
  }, [isMobile])

  return <source src={src} type="video/mp4" />
}

export default SizzleSource
